import useDevice from 'hooks/DesktopDevice.hook';
import { Box, Stack } from 'styles';
import FormContactUs from './FormContactUs.component';

const ContactUs = () => {
	const { isDesktop } = useDevice();

	return (
		<Stack
			height="100%"
			direction="row"
			gap={isDesktop ? '268px' : '63px'}
			pb={isDesktop ? '60px' : '30px'}
			pt={isDesktop ? '36.5px' : '26px'}
		>
			<Box width="100%" overflow="auto" paddingRight="5px">
				{/* <iframe
					title="iFrame-qualtrics"
					className="iFrame-custom"
					width="100%"
					id="iFrame-contact"
					loading="lazy"
					height="600px"
					src={`${QUALTRICS_URL}${getLocaleLanguage()}`}
				></iframe> */}
				<FormContactUs />
			</Box>
		</Stack>
	);
};

export default ContactUs;
